import React from 'react'

import Layout from '../../components/Layout'
import Products from '../../components/Products';

const ProductIndexPage = () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <Products />
        </div>
      </div>
    </section>
  </Layout>
);

export default ProductIndexPage;